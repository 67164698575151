import {
    ProductVideo1,
    ProductVideo2,
    ProductVideo3,
    ProductVideo4,
    ProductVideo5,
    ProductVideo6,
    ProductVideo7,
    ProductVideo8,
    ProductVideo9,
    ProductVideo10,
    ProductVideo11,
    ProductVideo12,
    ProductVideo13,
    ProductVideo14,
    ProductVideo15,
    video1Poster,
    video2Poster,
    video3Poster,
    video4Poster,
    video5Poster,
    video6Poster,
    video7Poster,
    video8Poster,
    video9Poster,
    video10Poster,
    video11Poster,
    video12Poster,
    video13Poster,
    video14Poster,
    video15Poster,
} from '../utils/video';


export const videos = [
    {
        id: 1,
        title: 'Memory form pillow',
        src: ProductVideo1,
        poster: video1Poster,
    },
    {
        id: 2,
        title: 'Armrest for Car',
        src: ProductVideo2,
        poster: video2Poster,
    },
    {
        id: 3,
        title: 'Functional pillow',
        src: ProductVideo3,
        poster: video3Poster,
    },
    {
        id: 4,
        title: 'Functional pillow with hot pack',
        src: ProductVideo4,
        poster: video4Poster,
    },
    {
        id: 5,
        title: 'Cat pillow',
        src: ProductVideo5,
        poster: video5Poster,
    },
    {
        id: 6,
        title: 'Lumbar pillows for car 1',
        src: ProductVideo6,
        poster: video6Poster,
    },
    {
        id: 7,
        title: 'Lumbar pillows for car 2',
        src: ProductVideo7,
        poster: video7Poster,
    },
    {
        id: 8,
        title: 'Lumbar pillows for car 3',
        src: ProductVideo8,
        poster: video8Poster,
    },
    {
        id: 9,
        title: 'Lumbar pillows for office chair',
        src: ProductVideo9,
        poster: video9Poster,
    },
    {
        id: 10,
        title: 'Lumber pillows for office chair',
        src: ProductVideo10,
        poster: video10Poster,
    },
    {
        id: 11,
        title: 'Adjustable functional pillow',
        src: ProductVideo11,
        poster: video11Poster,
    },
    {
        id: 12,
        title: 'Neck pillow',
        src: ProductVideo12,
        poster: video12Poster,
    },
    {
        id: 13,
        title: 'Pregnancy pillow',
        src: ProductVideo13,
        poster: video13Poster,
    },
    {
        id: 14,
        title: 'Seat cushion',
        src: ProductVideo14,
        poster: video14Poster,
    },
    {
        id: 15,
        title: 'Memory form pillow',
        src: ProductVideo15,
        poster: video15Poster,
    },
    // Add more videos as needed
];
