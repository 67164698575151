import {
  highlightFirstVideo,
  highlightFourthVideo,
  highlightSecondVideo,
  highlightThirdVideo,
  heroAboutImage,
  aboutFirstImage,
  aboutSecondImage,
  aboutThirdImage,
  CompanyProfile,
  ProductCatalog,
  BusinessProposal,
  costco,
  kuka,
  sleemon,
  samsClub,
  amazon,
  pillowImg,
  mattressImg,
  matImg,
  supportImg,
  factoriesImg,
  factoriesinnerImg,
  professorsImg,
  people1Img,
  people2Img,
  people3Img,
} from "../utils";

import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

export const navLists = ["About", "Resources", "Videos"];

export const hightlightsSlides = [
  {
    id: 1,
    textLists: [
      "Factory direct supply",
      "250+ skilled workers",
      "Quality-assured production",
    ],
    video: highlightFirstVideo,
    videoDuration: 4,
  },
  {
    id: 2,
    textLists: [
      "Market-driven development",
      "100+ patents. ",
      "Rapid prototyping.",
    ],
    video: highlightSecondVideo,
    videoDuration: 5,
  },
  {
    id: 3,
    textLists: [
      "Fastest delivery cycle",
      "Innovative supply chain",
      "One stop warehousing service.",
    ],
    video: highlightThirdVideo,
    videoDuration: 2,
  },
  {
    id: 4,
    textLists: ["Price is good", "Product is excellent."],
    video: highlightFourthVideo,
    videoDuration: 3.63,
  },
];

export const sizes = [
  { label: '6.1"', value: "small" },
  { label: '6.7"', value: "large" },
];

export const footerLinks = [
  { text: "Privacy Policy", href: "https://www.aimycomfort.com/store/privacy-policy" },
  { text: "Sales Policy", href: "https://www.aimycomfort.com/store/sales-policy" },
  { text: "Our Store", href: "https://www.aimycomfort.com/store/" },
  { text: "FAQ", href: "/faq" },
];

export const socialLinks = [
  { name: 'Facebook', url: 'https://www.facebook.com/aimycomfort', icon: faFacebook },
  { name: 'Twitter', url: 'https://x.com/aimycomfort', icon: faTwitter },
  { name: 'Instagram', url: 'https://www.instagram.com', icon: faInstagram },
  { name: 'Youtube', url: 'https://www.youtube.com/channel/UCLHHLSQE8HeFC3g1-CUiu8w', icon: faYoutube }
  // Add more social links as needed
]

export const aboutSections = [
  {
    type: 'hero',
    img: heroAboutImage,
    title: 'About',
    text: 'An integrated polyurethane industry chain enterprise'
  },
  {
    type: 'section',
    img: aboutFirstImage,
    title: 'About us',
    text: 'From ergonomic chairs to premium bedding, we offer items designed to improve daily life. Our development process ensures quick prototyping and production, tailored to market trends and your needs. We are ready to transform our quality products into successful extensions of your brand.',
    pdfLink: CompanyProfile
  },
  {
    type: 'section',
    img: aboutSecondImage,
    title: 'Products Overview',
    text: 'We offer home products especially polyurethane products for home, office supplies, automotive interiors, pet products... Any products you can think of that relieve fatigue and bring comfort, we design it. ',
    pdfLink: ProductCatalog
  },
  {
    type: 'section',
    img: aboutThirdImage,
    title: 'Great Support',
    text: "We proudly partner with renowned brands like Kuka and Sleemon, as well as rising stars such as Cicido, Jago, and Nira in China. Our products grace the shelves of COSTCO and Sam's Club. Whether you're an industry giant or a startup, we offer unwavering support.",
    pdfLink: BusinessProposal
  }
];

// testimonials on about-us page.

export const testimonials = [
  {
    quote:
      "There is almost no odor. The one I bought from another store had a very strong smell, but this one basically has no smell, just a little bit. The seller mentioned that due to the high volume of orders, the products are freshly made, so after receiving the item, you just need to ventilate it for a little while. I strongly recommend this to students. The design is very nice; it's truly a lunch break essential. You no longer have to worry about your hands hurting from resting on them during naps.",
    name: "Michael Johnson",
    title: "Director of AlphaStream Technologies",
  },
  {
    quote:
      "I am really satisfied with the pillow! The seller has a good reputation, the craftsmanship is meticulous, and their attitude is excellent. The style also matches my taste, and it works great. Highly recommended! The seller is friendly and attentive, the shipping speed is fast, and it was an extremely satisfying shopping experience!",
    name: "Michael Johnson",
    title: "Director of AlphaStream Technologies",
  },
  {
    quote:
      "I received the pillow and used it the same night. Sitting at the office every day has caused some serious neck issues, which I guess is an occupational hazard. The stiffness in my neck was causing dizziness and lightheadedness. The first night using this pillow felt a bit uncomfortable, probably because of my straightened cervical spine, but now I've gotten used to it. When lying flat, the recessed part of the pillow holds my head in place, and my neck is well-supported by the middle section. I wake up feeling very comfortable and refreshed! ",
    name: "Michael Johnson",
    title: "Director of AlphaStream Technologies",
  },
  {
    quote:
      "The design of this product looks good, and after trying it, the texture feels quite nice. Firstly, the fabric stitching is very tight, and secondly, there is no noticeable odor, which I am quite satisfied with. The outer layer is removable, making it easy to clean. After placing it on the seat, it provides excellent support for the lower back, making driving much more comfortable. I feel more alert while driving and not as easily fatigued. I think I should have bought this sooner. It's definitely worth the price",
    name: "Michael Johnson",
    title: "Director of AlphaStream Technologies",
  },
];

export const companies = [
  {
    id: 1,
    name: "costco",
    img: costco,
  },
  {
    id: 2,
    name: "kuka",
    img: kuka,
  },
  {
    id: 3,
    name: "sleemon",
    img: sleemon,
  },
  {
    id: 4,
    name: "sams-club",
    img: samsClub,
  },
  {
    id: 5,
    name: "amazon",
    img: amazon,
  },
];

export const socialMedia = [
  {
    id: 1,
    img: "/git.svg",
  },
  {
    id: 2,
    img: "/twit.svg",
  },
  {
    id: 3,
    img: "/link.svg",
  },
];

export const recentTradeShows = [
  {
    title: 'Trade Show 1',
    date: 'January 15, 2023',
    description: 'Description of Trade Show 1.',
  },
  {
    title: 'Trade Show 2',
    date: 'March 10, 2023',
    description: 'Description of Trade Show 2.',
  },
  // Add more recent trade shows as needed
];

export const futureTradeShows = [
  {
    title: 'Trade Show 3',
    date: 'July 20, 2023',
    description: 'Description of Trade Show 3.',
  },
  {
    title: 'Trade Show 4',
    date: 'October 5, 2023',
    description: 'Description of Trade Show 4.',
  },
  // Add more future trade shows as needed
];

export const resourceTypes = ['All FAQs', 'Products knowledge', 'Shipping & Packaging'];

export const featuredResources = [
  {
    id: 1,
    type: 'INTRO',
    title: 'About AimyComfort',
    description: 'We have mastered the core technology of high-quality polyurethane products.',
    image: aboutFirstImage,
    pdfLink: CompanyProfile
  },
  {
    id: 2,
    type: 'CATALOG',
    title: 'Products Overview',
    description: 'From ergonomic chairs to premium bedding, we offer items designed to improve daily life.',
    image: aboutSecondImage,
    pdfLink: ProductCatalog
  },
  {
    id: 3,
    type: 'PROPOSAL',
    title: 'Full Support',
    description: 'Whether you are an industry giant or a startup, we offer unwavering support.',
    image: aboutThirdImage,
    pdfLink: BusinessProposal
  }
];

export const resourcesList = [
  {
    id: 1,
    type: 'Products knowledge',
    question: 'What are the standard specifications for memory foam pillows?',
    answer: 'Our memory foam pillows typically come in dimensions of 60x40x12cm, with a density of 45-60kg/m³. The material used is high-quality polyurethane with CertiPUR-US certification. We offer customizable firmness levels and can adjust specifications based on your requirements.'
  },
  {
    id: 2,
    type: 'Products knowledge',
    question: 'How do you ensure quality control in foam production?',
    answer: 'We implement a comprehensive quality control system that includes: raw material testing, density checks, resilience testing, durability assessment, and final product inspection. Each batch undergoes thorough testing to meet international standards and customer specifications.'
  },
  {
    id: 3,
    type: 'Products knowledge',
    question: 'What is the minimum order quantity (MOQ) for custom foam products?',
    answer: 'Our standard MOQ is 100 pieces for custom foam products. However, we offer flexible solutions and can discuss adjustments based on your specific needs. For larger orders, we provide competitive volume pricing and priority production scheduling.'
  },
  {
    id: 4,
    type: 'Shipping & Packaging',
    question: 'What certifications do your foam products have?',
    answer: 'Our products are certified with: CertiPUR-US, OEKO-TEX Standard 100, ISO 9001:2015 Quality Management System, and meet various international safety standards. We can also obtain additional certifications based on specific market requirements.'
  },
  {
    id: 5,
    type: 'Shipping & Packaging',
    question: 'What is the Lead Time for mass order?',
    answer: 'Our standard MOQ is 100 pieces for custom foam products. However, we offer flexible solutions and can discuss adjustments based on your specific needs. For larger orders, we provide competitive volume pricing and priority production scheduling.'
  },
  {
    id: 6,
    type: 'Shipping & Packaging',
    question: 'How could you ensure our Money Safety and Quality Excellence?',
    answer: 'We will produce samples before mass production, the final inspection will be inspected before shipment, and the factory has inspectors in each department to check the quality of each product. For any quality problems, we always provide perfect after-sales and service attitude.'
  },
  {
    id: 7,
    type: 'Shipping & Packaging',
    question: 'What are the primary shipping methods?',
    answer: 'Common packaging (High shipping cost), rolling (reduced volumn, 1-year valid time), vacuum compression (2cm thickness after compression, significantly reduces shipping cost, 3-month valid time)'
  },
  {
    id: 8,
    type: 'Shipping & Packaging',
    question: 'How many pieces can fit into a 20ft or 40ft container?',
    answer: 'Packaging methods and quantities depend on the product, There are common packaging, rolling, vacuum compression to choose from.'
  },
  {
    id: 9,
    type: 'Products knowledge',
    question: 'Does your product support logo customization?',
    answer: 'The woven label logo costs around $50 per 1000 pieces, the embroidered logo costs around $0.25 per piece (the price depends on the size), the maximum size for the heat transfer label is 7x7 cm, and a heat transfer machine costs $280 each.'
  },
  {
    id: 10,
    type: 'Products knowledge',
    question: 'What is the usual sampling time?',
    answer: 'The usual sample production time is 3-5 days while it takes 7 days for a new sample order, we need to adjust the outerwear to ensure proper fit which requires some additional time.'
  },
  {
    id: 11,
    type: 'Shipping & Packaging',
    question: 'What are the packaging methods and corresponding materials?',
    answer: 'We offer various packaging methods including roll packaging, flat packaging, folding packaging, and heat shrink packaging. Available materials include: PE bags, OPP bags, PVC bags, vacuum compression bags, none-woven bags, color boxes, and flying boxes. PE bags, OPP bags, PVC bags, and vacuum compression bags are primarily made of polyethylene. Non-woven bags are made of polyester (polyester fiber). Color boxes and airplane boxes are made of corrugated paper.'
  }
];

export const teamMembers = [
  {
    id: 1,
    name: 'Arvin Wang',
    title: 'Sales Manager',
    description: 'Haifeng has extensive experience in OEM/ODM solutions. His expertise spans product development, manufacturing processes, and international trade regulations.',
    image: people1Img,
    linkedIn: 'https://www.linkedin.com/in/arvinwang/'
  },
  {
    id: 2,
    name: 'Real Wei',
    title: 'Overseas Manager',
    description: 'With a deep understanding of foam manufacturing and customization, Real excels at helping clients find the perfect solutions for their needs.',
    image: people2Img,
    linkedIn: 'https://www.linkedin.com/in/real-wei-135154321/'
  },
  {
    id: 3,
    name: 'Irene Dong',
    title: 'Overseas Manager',
    description: 'Irene focuses on building strong client relationships and providing comprehensive support. Ensure successful project outcomes for customers.',
    image: people3Img,
    linkedIn: 'https://www.linkedin.com/in/irene-dong-5a1b01333/'
  }
];

export const solutions = [
  {
    id: 1,
    icon: 'FaBullseye',
    title: "Niche Focus",
    description: "We offer products that aremore unique or new to themarket than ourcompetitorsIn home goods field. "
  },
  {
    id: 2,
    icon: 'FaRocket',
    title: "Agile Market",
    description: "Offers a market advantage that is more focused based on market needs than a moregeneral market."
  },
  {
    id: 3,
    icon: 'FaCheckCircle',
    title: "One-Stop Service",
    description: "We ensure you receive One-stop solutions, backed by our complete product export qualifications."
  },
  {
    id: 4,
    icon: 'FaHandsHelping',
    title: "Full Support",
    description: "With our hands-on approach,your products are receiving meticulous attention through the process."
  }
];

export const caseStudies = [
  {
    id: 1,
    image: factoriesImg,
    title: "20yrs in Polyurethane industry",
    description: "Founded in 2005, The only manufacturer can provide 3 major additives for Polyurethane",
    link: "#"
  },
  {
    id: 2,
    image: factoriesinnerImg,
    title: "2 factory Bases",
    description: "Provides with unique raw materials and innovations to ensure supply efficiency",
    link: "#"
  },
  {
    id: 3,
    image: professorsImg,
    title: "2 R&D Bases",
    description: "Team members include professors and doctors in Chemical and Polymer Science",
    link: "#"
  }
];

export const products = [
  {
    id: 1,
    name: "Pillow Series",
    image: pillowImg,
    description: "According to customer needs, we can customize pillows of different shapes and functions, providing unparalleled comfort and enjoying our cost-effective and snug pillows."
  },
  {
    id: 2,
    name: "Support Series",
    image: supportImg,
    description: "Ergonomic lumbar support pad, seat cushion, headrest, made of slow resiling memory foam, helps maintain correct posture, reduce fatigue, and provide a comfortable experience."
  },
  {
    id: 3,
    name: "Mattress Series",
    image: mattressImg,
    description: "Simple design, fashionable and durable, Soft and skin friendly fabric, soft touch, breathable and comfortable, Slow rebound memory cotton, absorbs impact force, adheres to human curves"
  },
  {
    id: 4,
    name: "PU Mat Series",
    image: matImg,
    description: "Use high elastic foam include polyurethane. Compared with traditional products, PU mat has better performance and can effectively reduce the emission of volatile organic compounds."
  }
];

export const companyIntroduction = {
  video: "https://www.youtube.com/embed/x1GUneg1ZZk?si=vezjnHqXu4M2e_wn",
  title: "Your Trusted Partner in Innovation",
  description: "With over two decades of experience, we've established ourselves as a leading manufacturer of polyurethane products. Our state-of-the-art facilities and dedicated team of experts ensure the highest quality standards in every product we deliver.",
  highlights: [
    "250+ skilled workers dedicated to quality",
    "100+ patents and innovative solutions",
    "Global partnerships with leading brands"
  ]
};



