import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import {
    FaChevronLeft,
    FaChevronRight,
    FaBullseye,
    FaRocket,
    FaCheckCircle,
    FaHandsHelping
} from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { aboutSections, companies, solutions, caseStudies, products, companyIntroduction } from '../constants';
import Clients from './Clients';

const HeroSection = ({ data }) => (
    <section className="relative h-[40vh] w-full">
        <img
            src={data.img}
            alt={data.title}
            className="w-full h-full object-cover"
            loading="eager"
        />
        <div className="absolute inset-0 bg-black/30 flex items-center justify-center">
            <div className="text-center text-white max-w-4xl px-4">
                <h1 className="text-5xl font-bold mb-6">About</h1>
                <p className="text-base max-w-xl mx-auto">{data.text}</p>
            </div>
        </div>
    </section>
);

const CompanyList = ({ companies }) => (
    <section className="flex flex-wrap items-center justify-center gap-8 md:gap-16 py-12">
        {companies.map(company => (
            <div
                key={company.id}
                className="w-24 md:w-32 transition-transform hover:scale-105"
            >
                <img
                    src={company.img}
                    alt={company.name}
                    className="w-full h-auto object-contain"
                    loading="lazy"
                />
            </div>
        ))}
    </section>
);

const VideoSection = () => (
    <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
            <div className="flex flex-col md:flex-row gap-8 items-center">
                <div className="w-full md:w-1/2">
                    <div className="relative aspect-video">
                        <iframe
                            className="absolute inset-0 w-full h-full rounded-lg shadow-lg"
                            src={companyIntroduction.video}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </div>
                <div className="w-full md:w-1/2 flex flex-col justify-center">
                    <h2 className="text-3xl font-bold mb-4">{companyIntroduction.title}</h2>
                    <p className="text-gray-600 mb-6">{companyIntroduction.description}</p>
                    <ul className="space-y-3">
                        {companyIntroduction.highlights.map((highlight, index) => (
                            <li key={index} className="pl-5 relative before:absolute before:left-0 before:top-[0.6rem] before:h-2 before:w-2 before:bg-red-500 before:rounded-full">
                                {highlight}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </section>
);

const ProductShowcase = () => (
    <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-12">
                Reliable Solutions in Foam Industry
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {products.map(product => (
                    <div
                        key={product.id}
                        className="bg-white rounded-lg shadow-md overflow-hidden transition-transform hover:scale-105"
                    >
                        <img
                            src={product.image}
                            alt={product.name}
                            className="w-full h-48 object-cover"
                            loading="lazy"
                        />
                        <div className="p-6">
                            <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
                            <p className="text-gray-600 mb-4">{product.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="text-center mt-12">
                <a
                    href="https://www.aimycomfort.com/store"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block bg-[rgb(41,151,255)] hover:bg-[rgb(41,151,255)]/90 text-white font-semibold px-8 py-3 rounded-md transition-colors"
                >
                    View All Products
                </a>
            </div>
        </div>
    </section>
);

const SolutionsGrid = () => {
    // Map icon strings to actual components
    const iconComponents = {
        FaBullseye,
        FaRocket,
        FaCheckCircle,
        FaHandsHelping
    };

    return (
        <section className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-4">
                <h2 className="text-4xl font-bold text-center mb-12">
                    Our Advantages
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {solutions.map(solution => {
                        const IconComponent = iconComponents[solution.icon];
                        return (
                            <div
                                key={solution.id}
                                className="group p-6 bg-gray-50 rounded-lg hover:bg-blue-50 transition-colors duration-300"
                            >
                                <div className="flex flex-col items-center text-center">
                                    <div className="mb-4 p-3 bg-blue-500 rounded-full group-hover:bg-blue-600 transition-colors">
                                        <IconComponent className="w-8 h-8" />
                                    </div>
                                    <h3 className="text-xl font-semibold mb-2">
                                        {solution.title}
                                    </h3>
                                    <p className="text-gray-600 text-sm">
                                        {solution.description}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

const CaseStudySection = () => {
    const CustomPrevArrow = ({ onClick }) => (
        <button
            onClick={onClick}
            className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white p-1 rounded-full shadow-lg transition-all"
            aria-label="Previous slide"
        >
            <FaChevronLeft className="w-4.5 h-4.5  text-gray-800" />
        </button>
    );

    const CustomNextArrow = ({ onClick }) => (
        <button
            onClick={onClick}
            className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white p-1 rounded-full shadow-lg transition-all"
            aria-label="Next slide"
        >
            <FaChevronRight className="w-4.5 h-4.5 text-gray-800" />
        </button>
    );

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <section className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-4">
                <h2 className="text-4xl font-bold text-center mb-12">
                    Why we're the leader in the industry
                </h2>
                <div className="relative">
                    <Slider {...settings}>
                        {caseStudies.map((study) => (
                            <div key={study.id} className="px-4">
                                <div className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform hover:scale-[1.02]">
                                    <img
                                        src={study.image}
                                        alt={study.title}
                                        className="w-full h-48 object-cover"
                                    />
                                    <div className="p-6">
                                        <h3 className="text-xl font-semibold mb-2">{study.title}</h3>
                                        <p className="text-gray-600">{study.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

const About = () => {
    const heroSection = aboutSections.find(section => section.type === 'hero');

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {heroSection && <HeroSection data={heroSection} />}
            <CompanyList companies={companies} />
            <VideoSection />
            <ProductShowcase />
            <SolutionsGrid />
            <CaseStudySection />
            <Clients />
        </Suspense>
    );
};

export default About;



