import { useState } from 'react';
import { aimyImg } from '../utils';
import { navLists } from '../constants';
import { useNavigate, Link } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleContactButtonClick = () => {
    setIsOpen(false); // Close the menu
    navigate('/contact');
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="w-full py-5 sm:px-10 px-5 flex justify-between items-center border-b border-[#eaeaea]">
      <nav className="w-full flex justify-between items-center py-3">

        <div className="sm:hidden ml-2">
          <button onClick={toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
              {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>

        <div className="flex flex-1 justify-start max-sm:hidden items-center">
          <Link to="/" rel="noopener noreferrer" className="pr-5 py-1 text-sm uppercase cursor-pointer text-gray-500 hover:text-black transition-all">
            Home
          </Link>
          <a href="https://www.aimycomfort.com/store" target="_blank" rel="noopener noreferrer" className="px-5 py-1 text-sm uppercase cursor-pointer text-gray-500 hover:text-black transition-all">
            Store
          </a>
          {navLists.map((nav, index) => (
            <Link key={index} to={`/${nav.toLowerCase().replace(/\s+/g, '-')}`} className="px-5 py-1 text-sm uppercase cursor-pointer text-gray-500 hover:text-black transition-all">
              {nav}
            </Link>
          ))}
        </div>

        {/* <Link to="/" className="absolute left-1/2 transform -translate-x-1/2 flex items-center">
          <img src={aimyImg} alt="aimycomfort home goods" width={160} />
        </Link> */}

        <div className="flex flex-1 justify-center">
          <Link to="/" className="flex items-center">
            <img src={aimyImg} alt="aimycomfort home goods" width={160} />
          </Link>
        </div>

        <div className="hidden sm:flex flex-1 justify-end items-baseline gap-7">
          <button onClick={handleContactButtonClick} className="text-sm px-4 py-1 bg-blue text-white font-semibold rounded-lg hover:bg-blue-700 transition-all">
            Contact Us
          </button>
        </div>
      </nav>

      {isOpen && (
        <div className={`fixed inset-0 bg-gray-800 bg-opacity-90 z-50 transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
          <div className="flex flex-col items-start justify-center h-full space-y-8 px-8">
            <button onClick={toggleMenu} className="text-white absolute top-5 right-5">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-8 w-8">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <Link to="/" onClick={toggleMenu} className="text-white text-2xl hover:text-gray-300 transition-all">
              Home
            </Link>
            <a href="https://www.aimycomfort.com/store" target="_blank" onClick={toggleMenu} className="text-white text-2xl hover:text-gray-300 transition-all">
              Store
            </a>
            {navLists.map((nav, index) => (
              <Link key={index} to={`/${nav.toLowerCase().replace(/\s+/g, '-')}`} onClick={toggleMenu} className="text-white text-2xl hover:text-gray-300 transition-all">
                {nav}
              </Link>
            ))}
            <button onClick={handleContactButtonClick} className="text-2xl px-4 py-2 bg-blue text-white font-semibold rounded-lg hover:bg-blue-700 transition-all">
              Contact Us
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
