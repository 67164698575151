

import video1 from "/assets/galleries/4d-air-layer-wave-pillow-filmage.mp4"
import video2 from "/assets/galleries/black-handrest-filmage.mp4"
import video3 from "/assets/galleries/cervical-pillow-filmage.mp4"
import video4 from "/assets/galleries/cervical-pillow-with-heated-function-filmage.mp4"
import video5 from "/assets/galleries/contour-pillow-filmage.mp4"
import video6 from "/assets/galleries/lumbar-cushion-for-car-2-filmage.mp4"
import video7 from "/assets/galleries/lumbar-cushion-for-car-3-filmage.mp4"
import video8 from "/assets/galleries/lumbar-cushion-for-car-4-filmage.mp4"
import video9 from "/assets/galleries/lumbar-cushion-for-car-filmage.mp4"
import video10 from "/assets/galleries/lumbar-pillow-filmage.mp4"
import video11 from "/assets/galleries/neck-pillow-with-air-sac-filmage.mp4"
import video12 from "/assets/galleries/small-neck-pillow-filmage.mp4"
import video13 from "/assets/galleries/multi-functionl-bed-cushion-filmage.mp4"
import video14 from "/assets/galleries/square-seat-cushion-filmage.mp4"
import video15 from "/assets/galleries/wave-pillow-filmage.mp4"

import poster1 from "/assets/galleries/4d-air-layer-wave-pillow-filmage.jpg"
import poster2 from "/assets/galleries/black-handrest-filmage.jpg"
import poster3 from "/assets/galleries/cervical-pillow-filmage.jpg"
import poster4 from "/assets/galleries/cervical-pillow-with-heated-function-filmage.jpg"
import poster5 from "/assets/galleries/contour-pillow-filmage.jpg"
import poster6 from "/assets/galleries/lumbar-cushion-for-car-2-filmage.jpg"
import poster7 from "/assets/galleries/lumbar-cushion-for-car-3-filmage.jpg"
import poster8 from "/assets/galleries/lumbar-cushion-for-car-4-filmage.jpg"
import poster9 from "/assets/galleries/lumbar-cushion-for-car-filmage.jpg"
import poster10 from "/assets/galleries/lumbar-pillow-filmage.jpg"
import poster11 from "/assets/galleries/neck-pillow-with-air-sac-filmage.jpg"
import poster12 from "/assets/galleries/small-neck-pillow-filmage.jpg"
import poster13 from "/assets/galleries/multi-functionl-bed-cushion-filmage.jpg"
import poster14 from "/assets/galleries/square-seat-cushion-filmage.jpg"
import poster15 from "/assets/galleries/wave-pillow-filmage.jpg"


export const ProductVideo1 = video1;
export const ProductVideo2 = video2;
export const ProductVideo3 = video3;
export const ProductVideo4 = video4;
export const ProductVideo5 = video5;
export const ProductVideo6 = video6;
export const ProductVideo7 = video7;
export const ProductVideo8 = video8;
export const ProductVideo9 = video9;
export const ProductVideo10 = video10;
export const ProductVideo11 = video11;
export const ProductVideo12 = video12;
export const ProductVideo13 = video13;
export const ProductVideo14 = video14;
export const ProductVideo15 = video15;

export const video1Poster = poster1;
export const video2Poster = poster2;
export const video3Poster = poster3;
export const video4Poster = poster4;
export const video5Poster = poster5;
export const video6Poster = poster6;
export const video7Poster = poster7;
export const video8Poster = poster8;
export const video9Poster = poster9;
export const video10Poster = poster10;
export const video11Poster = poster11;
export const video12Poster = poster12;
export const video13Poster = poster13;
export const video14Poster = poster14;
export const video15Poster = poster15;

