import hero from "/assets/images/hero.jpeg";

export const heroImg = hero;

import hmv from "/assets/videos/hero.mp4";
import highlightFirstmv from "/assets/videos/highlight-first.mp4";
import highlightSectmv from "/assets/videos/highlight-sec.mp4";
import highlightThirdmv from "/assets/videos/highlight-third.mp4";
import highlightFourthmv from "/assets/videos/highlight-fourth.mp4";
import exploremv from "/assets/videos/explore.mp4";

import aimy from "/assets/images/aimy.png";

import watch from "/assets/images/watch.svg";
import right from "/assets/images/right.svg";
import replay from "/assets/images/replay.svg";
import play from "/assets/images/play.svg";
import pause from "/assets/images/pause.svg";

import explore1 from "/assets/images/explore1.jpg";
import explore2 from "/assets/images/explore2.jpg";

// about us page images

import about from "/assets/images/about.jpg";
import about1 from "/assets/images/Factory-service-min.jpg";
import about2 from "/assets/images/Factory-show-room-min.jpg";
import about3 from "/assets/images/Design-support-min.jpg";
import profile from "/assets/files/CompanyProfile-aimyComfort.pdf";
import catalog from "/assets/files/aimyComfortCatalog.pdf";
import proposal from "/assets/files/BusinessProposal-aimyComfort.pdf";

import logo1 from "/assets/images/amazon-logo.png";
import logo2 from "/assets/images/costco.png";
import logo3 from "/assets/images/kuka.png";
import logo4 from "/assets/images/sams-club.png";
import logo5 from "/assets/images/sleemon.jpg";

import pillow from "/assets/images/aimy-pillow.jpg";
import mattress from "/assets/images/mattress.jpg";
import mat from "/assets/images/mat.jpg";
import support from "/assets/images/cushion.jpg";

import factories from "/assets/images/factories-min.jpg";
import professors from "/assets/images/professors-min.jpg";
import factoriesinner from "/assets/images/factoriesinner-min.jpg"

import people1 from "/assets/images/wanghaifeng.jpg"
import people2 from "/assets/images/real.jpg"
import people3 from "/assets/images/Irene.jpg"


export const heroVideo = hmv;
export const highlightFirstVideo = highlightFirstmv;
export const highlightSecondVideo = highlightSectmv;
export const highlightThirdVideo = highlightThirdmv;
export const highlightFourthVideo = highlightFourthmv;
export const exploreVideo = exploremv;

export const aimyImg = aimy;
export const watchImg = watch;
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;

export const explore1Img = explore1;
export const explore2Img = explore2;

// Below is the images instored for the about us page

export const heroAboutImage = about;
export const aboutFirstImage = about1;
export const aboutSecondImage = about2;
export const aboutThirdImage = about3;
export const BusinessProposal = proposal;
export const ProductCatalog = catalog;
export const CompanyProfile = profile;

export const costco = logo1;
export const kuka = logo2;
export const sleemon = logo5;
export const samsClub = logo4;
export const amazon = logo3;

export const pillowImg = pillow;
export const mattressImg = mattress;
export const matImg = mat;
export const supportImg = support;

export const factoriesImg = factories;
export const factoriesinnerImg = factoriesinner;
export const professorsImg = professors;

export const people1Img = people1;
export const people2Img = people2;
export const people3Img = people3;