import React from "react";

import { companies, testimonials } from "../constants";
import { InfiniteMovingCards } from "./ui/InifiniteCards";

const Clients = () => {
    return (
        <section id="testimonials" className="py-2 md:py-10">
            <h2 className="heading">
                Kind words from
                <span className="text-blue"> satisfied clients</span>
            </h2>

            <div className="flex flex-col items-center max-lg:mt-10">
                <div
                    // remove bg-white dark:bg-black dark:bg-grid-white/[0.05], h-[40rem] to 30rem , md:h-[30rem] are for the responsive design
                    className="h-[30vh] md:h-[25rem] rounded-md flex flex-col antialiased  items-center justify-center relative overflow-hidden"
                >
                    <InfiniteMovingCards
                        items={testimonials}
                        direction="right"
                        speed="slow"
                    />
                </div>

            </div>
        </section>
    );
};

export default Clients;
