import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const faqData = [
    {
        section: "About Products",
        questions: [
            {
                question: "What are the filling materials of your products?",
                answer: "The filling material for memory foam is polyurethane, The filling material for PP cotton is polypropylene cotton"
            },
            {
                question: "We want to know the density and color of the pillow inner, are they customizable?",
                answer: "The regular density of memory foam inner is 40D. The original color is white. We can customize its density, weight, and color (The original color is white, blue and black are also the options)."
            },
            {
                question: "We want to know more about the fabrics?",
                answer: "The common fabric materials are polyester, cotton, polyurethane, tencel. The fabric normally consists of 95%-98% polyester and 5%-2% polypropylene, tencel or cotton, you need to take different material cost into consideration. The common fabric name: Crystal Velvet, 3D Mesh Fabric, Yoga Fabric, Air Layer Fabric, Bird's Eye Fabric, Milk Silk, White Jersey Fabric…"
            },
            {
                question: "How much does the mold cost for customization?",
                answer: "We offer service to product design with 3D models, the mold cost depends on the size and complexity of the product. Generally, the cost of a mold is around $300-$500, a set of molds can typically produce 80-100 items per day. We suggest just a set of molds for the 1st order if the customer’s order quantity is large and demands fast delivery, once the order is getting stable, more sets of molds will be needed to shorten the production time."
            }
        ]
    },
    {
        section: "Shipping & Maintaining",
        questions: [
            {
                question: "What are the packaging methods and corresponding materials?",
                answer: "PE bags, OPP bags, PVC bags, vacuum compression bags, none-woven bags, color boxes, and flying boxes. PE bags, OPP bags, PVC bags, and vacuum compression bags are primarily made of polyethylene. Non-woven bags are made of polyester (polyester fiber).Color boxes and airplane boxes are made of corrugated paper."
            },
            {
                question: "What are the primary shipping methods?",
                answer: "Common packaging (High shipping cost), rolling (reduced volumn, 1-year valid time), vacuum compression (2cm thickness after compression, significantly reduces shipping cost, 3-month valid time)."
            },
            {
                question: "How many pieces can fit into a 20ft or 40ft container?",
                answer: "Packaging methods and quantities depend on the product, There are common packaging, rolling, vacuum compression to choose from."
            },
            {
                question: "How should the product be cleaned and maintained?",
                answer: "Memory foam should not be immersed in water. If there are stains on the memory foam surface, you can use a dry towel with a little water to gently wipe it, then let it air dry naturally. The outer cover can be machine washed."
            }
        ]
    },
];

const Faq = () => {
    return (
        <div className="py-15">
            <section className="mb-12">
                <img src="/assets/images/faqbanner.jpg" alt="FAQ" className="w-full h-64 object-cover bg-grey" />
                <div className="mt-8 text-center">
                    <h1 className="text-3xl font-bold">Don't see your question here? Drop us a line!</h1>
                    <p className="mt-4">Check out our FAQ or contact us below</p>
                    <p className="mt-2"><a href="mailto:sales@aimycomfort.com" className="text-blue-600">sales@aimycomfort.com</a></p>
                    {/* <p className="mt-2">Chat with us</p>
                    <p className="mt-2">Monday - Friday / 9am - 5pm EST</p>
                    <p className="mt-2">Saturday - Sunday / Closed</p> */}
                </div>
            </section>

            {faqData.map((section, index) => (
                <FaqSection key={index} section={section} />
            ))}
        </div>
    );
};

const FaqSection = ({ section }) => {
    return (
        <section className="mx-auto px-4 py-8 screen-max-width">
            <h3 className="text-xl font-bold mb-6">{section.section}</h3>
            <div className="space-y-4">
                {section.questions.map((faq, index) => (
                    <FaqItem key={index} faq={faq} />
                ))}
            </div>
        </section>
    );
};

const FaqItem = ({ faq }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow">
            <div
                className="font-semibold cursor-pointer flex justify-between items-center"
                onClick={toggleOpen}
            >
                {faq.question}
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </div>
            {isOpen && <p className="mt-2">{faq.answer}</p>}
        </div>
    );
};

export default Faq;
