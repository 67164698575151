import React, { useState } from 'react';
import { FaArrowRight, FaChevronRight, FaChevronDown, FaChevronUp, FaYoutube } from 'react-icons/fa';
import { resourceTypes, featuredResources, resourcesList, teamMembers } from '../constants';

const FeaturedResourceCard = ({ resource }) => (
    <div className="h-full group bg-white rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-[1.02]">
        <img
            src={resource.image}
            alt={resource.title}
            className="w-full h-48 object-cover"
        />
        <div className="p-6 flex flex-col h-[calc(100%-12rem)]">
            <span className="text-sm font-semibold text-[rgb(41,151,255)] mb-2 block">
                {resource.type}
            </span>
            <h3 className="text-xl font-bold mb-2">{resource.title}</h3>
            <p className="text-gray-600 mb-4 flex-grow">{resource.description}</p>
            <a
                href={resource.pdfLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-[rgb(41,151,255)] group-hover:translate-x-2 transition-transform mt-auto"
            >
                <span className="font-semibold mr-2">Download</span>
                <FaArrowRight />
            </a>
        </div>
    </div>
);

const CTABanner = () => (
    <section className="bg-gray-900 text-white py-16">
        <div className="max-w-7xl mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold mb-4">Bring comforts to your customers</h2>
            <p className="text-xl text-white-200 mb-8 max-w-2xl mx-auto">
                Professional OEM/ODM Polyurethane products factory in China
            </p>
            <a
                href="https://www.youtube.com/@aimycomfort"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 bg-[rgb(41,151,255)] hover:bg-[rgb(41,151,255)]/90 text-white px-6 py-3 rounded-full transition-colors"
            >
                <FaYoutube className="w-5 h-5" />
                <span>Visit Our YouTube Channel</span>
            </a>
        </div>
    </section>
);

const ResourceFilter = ({ activeFilter, setActiveFilter }) => (
    <div className="flex flex-wrap gap-4 mb-6 max-w-2xl mx-auto">
        {resourceTypes.map((type) => (
            <button
                key={type}
                onClick={() => setActiveFilter(type)}
                className={`px-4 py-2 rounded-full transition-all ${activeFilter === type
                    ? 'bg-[rgb(41,151,255)] text-white'
                    : 'bg-gray-100 hover:bg-gray-200 text-gray-700 border border-gray-300'
                    }`}
            >
                {type}
            </button>
        ))}
    </div>
);

const ResourceListItem = ({ resource }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b border-gray-200 last:border-none">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full py-5 px-4 flex items-center justify-between hover:bg-gray-50 transition-colors"
            >
                <div className="flex flex-col items-start text-left">
                    <span className="text-sm font-semibold text-[rgb(41,151,255)] mb-2">
                        {resource.type}
                    </span>
                    <h3 className="text-lg font-semibold text-gray-900">
                        {resource.question}
                    </h3>
                </div>
                <span className="ml-6 flex-shrink-0">
                    {isOpen ? (
                        <FaChevronUp className="h-6 w-6 text-[rgb(41,151,255)]" />
                    ) : (
                        <FaChevronDown className="h-6 w-6 text-gray-400" />
                    )}
                </span>
            </button>

            {isOpen && (
                <div className="px-4 pb-5">
                    <p className="text-gray-600">
                        {resource.answer}
                    </p>
                </div>
            )}
        </div>
    );
};

const TeamSection = () => (
    <section className="bg-gray-50 py-16">
        <div className="max-w-3xl mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-12">Meet Our Sales Team</h2>

            <div className="space-y-8">
                {teamMembers.map(member => (
                    <div key={member.id} className="flex flex-col sm:flex-row items-center sm:items-start gap-6 bg-white p-6 rounded-lg shadow-sm">
                        <img
                            src={member.image}
                            alt={member.name}
                            className="rounded-full w-32 h-32 object-cover flex-shrink-0"
                        />
                        <div>
                            <h3 className="text-xl font-bold mb-1 text-center sm:text-left">{member.name}</h3>
                            <p className="text-gray-600 mb-3 text-center sm:text-left">{member.title}</p>
                            <p className="text-gray-600 mb-4">{member.description}</p>
                            <a
                                href={member.linkedIn}
                                className="text-[rgb(41,151,255)] hover:text-[rgb(41,151,255)]/80 font-semibold inline-flex items-center gap-2"
                            >
                                Connect on LinkedIn
                                <FaArrowRight className="w-4 h-4" />
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
);

const Resources = () => {
    const [activeFilter, setActiveFilter] = useState('All FAQs');

    // Filter resources based on active filter
    const filteredResources = resourcesList.filter(resource =>
        activeFilter === 'All FAQs' ? true : resource.type === activeFilter
    );

    return (
        <div className="min-h-screen">
            {/* Featured Resources Grid */}
            <section className="py-16">
                <div className="max-w-7xl mx-auto px-4">
                    <h1 className="text-4xl font-bold text-center mb-12">AimyComfort Resources</h1>
                    <div className="relative">
                        <div className="flex overflow-x-auto pb-6 gap-4 snap-x snap-mandatory md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8">
                            {featuredResources.map((resource) => (
                                <div key={resource.id} className="w-[80vw] flex-shrink-0 snap-start md:w-auto">
                                    <FeaturedResourceCard resource={resource} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <CTABanner />

            {/* Resource Filter and List */}
            <section className="py-16">
                <div className="max-w-7xl mx-auto px-4">
                    <ResourceFilter
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                    />
                    <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-sm">
                        {filteredResources.map((resource) => (
                            <ResourceListItem key={resource.id} resource={resource} />
                        ))}
                        {filteredResources.length === 0 && (
                            <p className="text-center text-gray-500 py-8">
                                No FAQs found for this category.
                            </p>
                        )}
                    </div>
                </div>
            </section>

            <TeamSection />
        </div>
    );
};

// Add this to your CSS or styles file to hide the scrollbar but maintain functionality
const styles = `
    .scrollbar-hide {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .scrollbar-hide::-webkit-scrollbar {
        display: none;  /* Chrome, Safari and Opera */
    }
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default Resources;