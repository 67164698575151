import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { footerLinks, socialLinks } from '../constants'

const Footer = () => {
  return (
    <footer className="pt-24 pb-10 sm:px-10 px-5">
      <div className="screen-max-width">
        <div>
          <p className="font-semibold text-gray text-xs">
            Call us at 1-318-969-1666
          </p>
          <p className="font-semibold text-gray text-xs">
            Or Email us at sales@aimycomfort.com
          </p>
          <div className="flex  space-x-4 mt-4">
            {socialLinks.map((link) => (
              <a key={link.name} href={link.url} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={link.icon} />
              </a>
            ))}
          </div>
        </div>

        <div className="bg-neutral-700 my-5 h-[1px] w-full" />

        <div className="flex md:flex-row flex-col md:items-center justify-between">
          <p className="font-semibold text-gray text-xs">Copright @ 2024 Aimy Excellent. All rights reserved.</p>
          {/* <div className="flex">
            {footerLinks.map((link, i) => (
              <p key={link.text} className="font-semibold text-gray text-xs">
                <a href={link.href} className="hover:underline">
                  {link}
                </a>
                {i !== footerLinks.length - 1 && (
                  <span className="mx-2"> | </span>
                )}
              </p>
            ))}
          </div> */}
          <div className="flex">
            {footerLinks.map((link, i) => (
              <p key={link.text} className="font-semibold text-gray text-xs">
                <a href={link.href} className="hover:underline">
                  {link.text}
                </a>
                {i !== footerLinks.length - 1 && (
                  <span className="mx-2"> | </span>
                )}
              </p>
            ))}
          </div>
        </div>


      </div>
    </footer>
  )
}

export default Footer;