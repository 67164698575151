import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import { useEffect, useRef, useState } from "react";
import { hightlightsSlides } from "../constants";
import { pauseImg, playImg, replayImg } from "../utils";

const VideoCarousel = () => {
  const videoRef = useRef([]);
  const [video, setVideo] = useState({
    startPlay: false,
    videoId: 0,
    isLastVideo: false,
    isPlaying: false,
  });

  const { isLastVideo, startPlay, videoId, isPlaying } = video;

  useGSAP(() => {
    gsap.to("#slider", {
      transform: `translateX(${-100 * videoId}%)`,
      duration: 2,
      ease: "power2.inOut",
    });

    gsap.to(videoRef.current[videoId], {
      scrollTrigger: {
        trigger: videoRef.current[videoId],
        toggleActions: "restart none none none",
      },
      onComplete: () => {
        setVideo((pre) => ({
          ...pre,
          startPlay: true,
          isPlaying: true,
        }));
      },
    });
  }, [videoId]);

  useEffect(() => {
    if (startPlay) {
      videoRef.current[videoId].play();
    } else {
      videoRef.current[videoId].pause();
    }
  }, [startPlay, videoId]);

  const handleProcess = (type) => {
    switch (type) {
      case "video-end":
        if (videoId < hightlightsSlides.length - 1) {
          setVideo((pre) => ({ ...pre, videoId: videoId + 1 }));
        } else {
          setVideo((pre) => ({ ...pre, isLastVideo: true, isPlaying: false }));
        }
        break;

      case "video-reset":
        setVideo({ startPlay: false, videoId: 0, isLastVideo: false, isPlaying: false });
        break;

      case "play":
        setVideo((pre) => ({ ...pre, startPlay: true, isPlaying: true }));
        break;

      case "pause":
        setVideo((pre) => ({ ...pre, startPlay: false, isPlaying: false }));
        break;

      default:
        return video;
    }
  };

  return (
    <>
      <div className="flex items-center">
        {hightlightsSlides.map((list, i) => (
          <div key={list.id} id="slider" className="sm:pr-20 pr-10 flex-shrink-0">
            <div className="video-carousel_container">
              <div className="w-full h-full flex-center rounded-3xl overflow-hidden bg-white">
                <video
                  id="video"
                  playsInline={true}
                  className="pointer-events-none"
                  preload="auto"
                  muted
                  ref={(el) => (videoRef.current[i] = el)}
                  onEnded={() => handleProcess("video-end")}
                  onPlay={() => setVideo((pre) => ({ ...pre, isPlaying: true }))}
                >
                  <source src={list.video} type="video/mp4" />
                </video>
              </div>

              <div className="absolute top-16 left-[5%] z-10 bg-black bg-opacity-50 p-3">
                {list.textLists.map((text, i) => (
                  <p key={i} className="md:text-2xl text-md font-medium text-white px-2">
                    {text}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="relative flex-center mt-10">
        <button className="control-btn">
          <img
            src={isLastVideo ? replayImg : !isPlaying ? playImg : pauseImg}
            alt={isLastVideo ? "replay" : !isPlaying ? "play" : "pause"}
            onClick={
              isLastVideo
                ? () => handleProcess("video-reset")
                : !isPlaying
                ? () => handleProcess("play")
                : () => handleProcess("pause")
            }
          />
        </button>
      </div>
    </>
  );
};

export default VideoCarousel;
