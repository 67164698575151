import { useGSAP } from '@gsap/react'
import React, { useRef } from 'react'
import { animateWithGsap } from '../../utils/animations';
import { explore1Img, explore2Img, exploreVideo } from '../../utils';
import gsap from 'gsap';

const Features = () => {
  const videoRef = useRef();

  useGSAP(() => {
    gsap.to('#exploreVideo', {
      scrollTrigger: {
        trigger: '#exploreVideo',
        toggleActions: 'play pause reverse restart',
        start: '-10% bottom',
      },
      onComplete: () => {
        videoRef.current.play();
      }
    })

    animateWithGsap('#features_title', { y: 0, opacity: 1 })
    animateWithGsap(
      '.g_grow',
      { scale: 1, opacity: 1, ease: 'power1' },
      { scrub: 5.5 }
    );
    animateWithGsap(
      '.g_text',
      { y: 0, opacity: 1, ease: 'power2.inOut', duration: 1 }
    )
  }, []);

  return (
    <section className="h-full common-padding bg-yellow relative overflow-hidden">
      <div className="screen-max-wdith">
        <div className="mb-12 w-full">
          <h1 id="features_title" className="section-heading">Explore the full market potential.</h1>
        </div>

        <div className="flex flex-col justify-center items-center overflow-hidden">
        <div className="mt-14 mb-12 md:mt-32 md:mb-24 md:pl-24  sm:pl-12">
  <h2 className="text-5xl lg:text-6xl font-semibold">Comfortable</h2>
  <h2 className="text-5xl lg:text-6xl font-semibold">Ergonomic Design</h2>
</div>
          <div className="flex-center flex-col sm:px-10">
            <div className="relative h-[50vh] w-full flex items-center">
              <video playsInline id="exploreVideo" className="w-full h-full object-cover object-center" preload="none" muted autoPlay ref={videoRef}>
                <source src={exploreVideo} type="video/mp4" />
              </video>
            </div>

            <div className="flex flex-col w-full relative">
              <div className="feature-video-container">
                <div className="overflow-hidden flex-1 h-[50vh]">
                  <img src={explore1Img} alt="titanium" className="feature-video g_grow" />
                </div>
                <div className="overflow-hidden flex-1 h-[50vh]">
                  <img src={explore2Img} alt="titanium 2" className="feature-video g_grow" />
                </div>
              </div>

              <div className="feature-text-container">
                <div className="flex-1 flex-center">
                  <p className="feature-text g_text">
                  At the heart of our operations lies advanced production technology, enabling us to {' '}
                    <span className="text-white">
                    seamlessly integrate research and development, production, sales, and OEM processing
                    </span>,
                    With a fully automated, high-precision production line, we guarantee efficient and quality-assured production.
                  </p>
                </div>

              
                <div className="flex-1 flex-center">
                  <p className="feature-text g_text">
                  Our company excels in meeting diverse customer needs {' '}
                    <span className="text-white">
                    by leveraging our extensive product 
                    inventory and a flexible production system.
                    </span>
                    We tackle the challenges associated with new product launches and bulk procurement, ensuring seamless transitions from development to delivery.

                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features